import logo from "../../assets/logo.png";
import "./HomePage.css";

const HomePage = () => {
  return (
    <div className="homepage">
      <img src={logo} alt="logo" className="logo" />
    </div>
  );
};

export default HomePage;
