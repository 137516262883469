import { useEffect, useState } from 'react';
import { convertFBTimestamp } from '../../../util';
import { firebase_firebase } from '../../../index';
import {
  getFirestore,
  collection,
  doc,
  getDocs,
  onSnapshot,
  updateDoc,
  Timestamp,
} from 'firebase/firestore';
import universalData from '../../../uniData.json';
import './Post.css';

const Post = () => {
  document.title = universalData.title + 'Post Announcement';

  const [displayData, setDisplayData] = useState({});
  const [dataStale, setDataStale] = useState(false);

  // set data to stale when display data is changed
  useEffect(() => {
    setDataStale(true);
  }, [displayData]);

  const clearAll = () => {
    Object.values(displayData).forEach((display) => {
      displayData[display.id].selected = false;
      displayData[display.id].title = '';
      displayData[display.id].body = '';
    });
    setDisplayData(displayData);

    pushToFirebase();
  };

  const pushToFirebase = () => {
    // get the current time using Firebase Date
    const timestamp = Timestamp.now();

    Object.values(displayData).forEach((display) => {
      displayData[display.id].timestamp = timestamp;
    });

    Object.values(displayData).forEach((display) => {
      // get the tv document and update the title, body, and timestamp
      const dbRef = doc(firebase_firebase, 'tv', display.id);
      updateDoc(dbRef, {
        title: display.title,
        body: display.body,
        timestamp: display.timestamp,
      });
    });

    setDisplayData(displayData);
    setTimeout(() => {
      setDataStale(false);
    }, 100);
  };

  const loadFromFirebase = () => {
    const dbRef = collection(firebase_firebase, 'tv');

    onSnapshot(dbRef, (querySnapshot) => {
      let displayData = {};
      querySnapshot.forEach((doc) => {
        displayData[doc.id] = {
          id: doc.id,
          ...doc.data(),
          selected: false,
        };
      });
      setDisplayData(displayData);
      setTimeout(() => {
        setDataStale(false);
      }, 100);
    });
  };

  useEffect(loadFromFirebase, []);

  return (
    <div
      onClick={() => {
        Object.values(displayData).forEach((display) => {
          displayData[display.id].selected = false;
        });
        setDisplayData({ ...displayData });
      }}
    >
      <h1>Post Announcement</h1>
      <p>
        Edit display content below. <br />
        Click on multiple displays to bulk edit. Click on a single display to
        edit it individually. <br />
        Submit changes by clicking the Submit button. <br />
        Urgently clear all displays by clicking the Clear All button. <br />
      </p>
      <br />
      {Object.values(displayData).map((display) => (
        <div
          className={'display ' + (display.selected ? 'selected' : '')}
          onClick={(e) => {
            displayData[display.id].selected =
              !displayData[display.id].selected;
            setDisplayData({ ...displayData });
            e.stopPropagation();
          }}
          key={display.id}
        >
          <code>{display.id}</code>
          &nbsp;&nbsp;&bull;&nbsp;&nbsp;
          <time>{convertFBTimestamp(display.timestamp)}</time>
          <input
            type="text"
            value={display.title}
            className="display__title"
            onChange={(e) => {
              Object.values(displayData).forEach((display) => {
                if (display.selected) display.title = e.target.value;
              });
              displayData[display.id].title = e.target.value;
              setDisplayData({ ...displayData });
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            disabled={!display.selected}
          />
          <textarea
            type="text"
            value={display.body}
            className="display__body"
            onChange={(e) => {
              Object.values(displayData).forEach((display) => {
                if (display.selected) display.body = e.target.value;
              });
              displayData[display.id].body = e.target.value;
              setDisplayData({ ...displayData });
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            disabled={!display.selected}
          />
        </div>
      ))}
      <br />
      <button className="button" onClick={pushToFirebase} disabled={!dataStale}>
        Submit
      </button>
      <button
        className="button"
        onClick={loadFromFirebase}
        disabled={!dataStale}
      >
        Cancel
      </button>
      <button className="button" onClick={clearAll}>
        Clear All
      </button>
      <br />
      <br />
    </div>
  );
};

export default Post;
