import { useEffect, useState } from 'react';
import { useQuery } from '../../util';
import { firebase_firebase } from '../../index';
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  onSnapshot,
} from 'firebase/firestore';
import { convertFBTimestamp } from '../../util';
import universalData from '../../uniData.json';
import './Display.css';

const Display = () => {
  document.title = universalData.title + 'Announcement Display';
  const query = useQuery();

  const [display, setDisplay] = useState({
    id: query.get('id'),
    title: '[NO ID] Configuration Error',
    body: 'This display has not been assigned a Display ID. Please contact an administrator.',
    timestamp: {},
  });

  useEffect(() => {
    if (display.id === null) return;

    const dbRef = collection(firebase_firebase, 'tv');
    // get the document with the id of the Display ID
    const docRef = doc(dbRef, display.id);

    try {
      // configure with onSnapshot listener
      onSnapshot(docRef, (doc) => {
        if (doc.exists())
          setDisplay({
            id: doc.id,
            ...doc.data(),
          });
        else
          setDisplay({
            id: '[INVALID ID]',
            title: '[INVALID ID] Configuration Error',
            body: 'This display has not been assigned a valid Display ID, or there is a configuration issue with the database. Please contact an administrator.',
            timestamp: {},
          });
      });
    } catch (error) {
      console.log('Error getting document:', error);
      setDisplay({
        id: '[DB ERROR]',
        title: '[DB ERROR] Configuration Error',
        body: 'There was an error retrieving display data from the database. Please contact an administrator.',
        timestamp: {},
      });
    }
  }, []);

  return (
    <div className="AnnouncementDisplay">
      <main>
        <h1>{display.title}</h1>
        <p>{display.body}</p>
      </main>

      <footer>
        Powered by ONEsystem | Display ID: {display.id}
        &nbsp;&nbsp;&bull;&nbsp;&nbsp;
        {convertFBTimestamp(display.timestamp)}
      </footer>
    </div>
  );
};

export default Display;
