import './CenteredContainer.css';
import HackTheHillLogo from '../../assets/HackTheHillLogo.svg';

const CenteredContainer = ({ logo, children }) => {
  return (
    <div className="centered-container">
      <div className="centered-container__box">
        {logo && (
          <img
            src={HackTheHillLogo}
            alt="HackTheHill Logo"
            className="centered-container__logo"
          />
        )}
        {children}
      </div>
    </div>
  );
};

export default CenteredContainer;
