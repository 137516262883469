import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import universalData from '../../uniData.json';
import './Payment.css';
import CenteredContainer from '../Components/CenteredContainer';
import { useQuery, isInt } from '../../util';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

// DOCUMENTATION: https://stripe.com/docs/payments/checkout/client

const Payment = () => {
  document.title = universalData.title + 'Sponsorship Payment';

  const query = useQuery();
  const [companyName, setCompanyName] = useState(query.get('company') || '');
  const [contributionAmount, setContributionAmount] = useState(
    query.get('amount') || '0'
  );

  const handlePayment = async (event) => {
    const successURL = `${universalData.publicURL}/sponsorship/success?company=${companyName}&session_id={CHECKOUT_SESSION_ID}`;

    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;

    const { error } = await stripe
      .redirectToCheckout({
        lineItems: [
          {
            price: process.env.REACT_APP_STRIPE_SPONSORSHIP_UNIT,
            quantity: parseInt(contributionAmount),
          },
        ],
        mode: 'payment',
        successUrl: successURL,
        cancelUrl: universalData.publicURL + '/sponsorship/cancelled',
        // TODO: optional variables, may want to look into the customer email + having a field on our page for it
        // customerEmail: '',
        // submitType: 'donate',
        billingAddressCollection: 'auto',
      })
      .catch((error) => {
        console.error('STRIPE API ERROR: ' + error);
        alert('An error occurred with Stripe: ' + error);
      });
    // TODO: implement error handling for this thing
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
  };

  return (
    <CenteredContainer logo>
      <p>
        Thank you for considering your sponsorship of Hack the Hill.
        <br />
        By submitting the form bellow, you are making a secure payment via
        Stripe.
      </p>
      <div className="payment__field">
        <div className="form-group">
          <label htmlFor="paymentCompany">Company Name</label>
          <input
            type="text"
            className="form-control"
            aria-describedby="paymentCompanyHelp"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="paymentTier">Sponsorship Tier</label>
          {/* TODO: can this be updated so that it will changed based on a from to range (will pick the same tier if the contribution amount is less then next tier) */}
          <select
            id="paymentTier"
            className="form-control"
            aria-describedby="paymentTierHelp"
            value={contributionAmount}
            onChange={(e) => setContributionAmount(e.target.value)}
          >
            {Object.keys({ 0: 'Other', ...universalData.amountToTier }).map(
              (amount) => (
                <option value={amount} key={amount}>
                  {universalData.amountToTier[amount]
                    ? `${universalData.amountToTier[amount]} ($${amount})`
                    : 'Other'}
                </option>
              )
            )}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="paymentQuantity">Contribution Amount</label>

          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">$</span>
            </div>
            <input
              type="text"
              className="form-control"
              aria-describedby="paymentQuantityHelp"
              value={contributionAmount}
              onChange={(e) => setContributionAmount(e.target.value)}
            />
            <div className="input-group-append">
              <span className="input-group-text">.00</span>
            </div>
          </div>
        </div>
        <button
          role="link"
          className="btn btn-primary"
          onClick={handlePayment}
          disabled={!isInt(contributionAmount)}
        >
          <strong>Checkout</strong> via Stripe
        </button>
      </div>
    </CenteredContainer>
  );
};

export default Payment;
