import uniData from '../../uniData.json';
import CenteredContainer from '../Components/CenteredContainer';

const SponsorshipCancelled = () => {
  document.title = `${uniData.title} Transaction Cancelled`;

  return (
    <CenteredContainer>
      <h1>Transaction Cancelled</h1>
      <p>
        Transaction was cancelled by Stripe API. If this error persists, please
        contact a Hack the Hill team member.
      </p>
      <p>
        <a href="/">Go Home</a>
      </p>
    </CenteredContainer>
  );
};

export default SponsorshipCancelled;
