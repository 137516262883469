import uniData from '../../uniData.json';
import './Success.css';
import CenteredContainer from '../Components/CenteredContainer';
import { useQuery } from '../../util';

const SponsorshipSuccess = () => {
  const query = useQuery();
  const companyName = query.get('company') || '';
  console.log(companyName);

  document.title = companyName
    ? `${uniData.title} Thank you ${companyName}!`
    : `${uniData.title} Thank you!`;

  return (
    <CenteredContainer logo>
      <h1>Thank You</h1>
      <p>
        {companyName
          ? `Thank you for your sponsorship, ${companyName}!`
          : 'Thank you for your sponsorship!'}{' '}
        We will get in touch soon.
      </p>
    </CenteredContainer>
  );
};

export default SponsorshipSuccess;
