import React from 'react';
import { useLocation } from 'react-router-dom';
import { Timestamp } from 'firebase/firestore';

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function isInt(str) {
  return !isNaN(str) && Number.isInteger(parseFloat(str));
}

// convert firebase timestamp to date and time string
// timestamp is a firebase.firestore.Timestamp object

export function convertFBTimestamp(timestamp) {
  // declare the timestamp as a firebase.firestore.Timestamp object
  const ts = new Timestamp(timestamp);
  // convert to a date object
  const seconds = ts.seconds.seconds;

  const dateString = new Date(seconds * 1000).toUTCString();
  return dateString;
  
}
