import universalData from '../../../uniData.json';
import { useState, useEffect } from 'react';
import { isInt } from '../../../util';

const GeneratePayment = () => {
  document.title = universalData.title + 'Generate Sponsorship Payment Link';

  const [companyName, setCompanyName] = useState('');
  const [contributionAmount, setContributionAmount] = useState('0');
  const [paymentUrl, setPaymentUrl] = useState('');

  useEffect(() => {
    if (!isInt(contributionAmount)) setPaymentUrl('Amount must be an integer');
    else
      setPaymentUrl(
        `${
          universalData.publicURL
        }/sponsorship/payment?amount=${encodeURIComponent(
          contributionAmount
        )}&company=${encodeURIComponent(companyName)}`
      );
  }, [companyName, contributionAmount]);

  return (
    <div style={{ padding: '20px' }}>
      <h1>Generate Sponsorship Payment</h1>
      <ol>
        <li>Enter the company name with proper capitalization</li>
        <li>Enter the contribution amount as an integer</li>
        <li>Copy the generated URL and send it to the company</li>
      </ol>

      <p>
        The following contribution tiers are available:{' '}
        <code>
          {Object.keys(universalData.amountToTier)
            .map((amount) => `${amount}: ${universalData.amountToTier[amount]}`)
            .join(', ')}
        </code>
        <br />
        Current tier:{' '}
        {universalData.amountToTier[contributionAmount] || 'Other'}
      </p>

      <input
        type="text"
        placeholder="Company Name"
        value={companyName}
        onChange={(e) => setCompanyName(e.target.value)}
      />
      <input
        type="text"
        placeholder="Contribution Amount"
        value={contributionAmount}
        onChange={(e) => setContributionAmount(e.target.value)}
      />

      <br />
      <br />
      <code>{paymentUrl}</code>
    </div>
  );
};

export default GeneratePayment;
