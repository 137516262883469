import logo from '../../assets/logo.png';
import './AdminDisabled.css';

const AdminDisabled = () => {
  document.title = 'Page Disabled';

  return (
    <div className="AdminDisabled">
      <header className="AdminDisabled-header">
        <img src={logo} className="AdminDisabled-logo" alt="logo" />
        <p>An admin has disabled this page.</p>
        <a
          className="AdminDisabled-link"
          href="https://one.hackthehill.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Go to the homepage
        </a>
      </header>
    </div>
  );
};

export default AdminDisabled;
