import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// import google firestore
import { getFirestore } from 'firebase/firestore';
import HomePage from './Pages/HomePage/HomePage';
import SponsorshipPayment from './Pages/Sponsorship/Payment';
import SponsorshipSuccess from './Pages/Sponsorship/Success';
import SponsorshipCancelled from './Pages/Sponsorship/Cancelled';

import AnnouncementDisplay from './Pages/Announcement/Display';
import SponsorshipGeneratePayment from './Pages/Internal/Sponsorship/GeneratePayment';
import AnnouncementPost from './Pages/Internal/Announcement/Post';
import AdminDisabled from './Pages/Errors/AdminDisabled';

const firebaseConfig = {
  apiKey: 'AIzaSyAGj2bKL02BFKOV4Sq8tgtUOmtpb9LS8jU',
  authDomain: 'hackthehill-302c2.firebaseapp.com',
  projectId: 'hackthehill-302c2',
  storageBucket: 'hackthehill-302c2.appspot.com',
  messagingSenderId: '29530292382',
  appId: '1:29530292382:web:6ce894f94281e9902bfb8f',
  measurementId: 'G-MXB1H588V7',
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        {/* External Routes */}
        <Route path="sponsorship/payment" element={<SponsorshipPayment />} />
        <Route path="sponsorship/success" element={<SponsorshipSuccess />} />
        <Route
          path="sponsorship/cancelled"
          element={<SponsorshipCancelled />}
        />
        <Route
          path="/announcement/display/"
          element={<AnnouncementDisplay />}
        />

        {/* Internal Routes */}
        <Route
          path="internal/sponsorship/genPayment"
          element={<SponsorshipGeneratePayment />}
        />
        <Route
          path="internal/announcement/post"
          element={<AnnouncementPost />}
        />

        {/* Other Routes */}
        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<AdminDisabled />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const firebase_app = initializeApp(firebaseConfig);
const firebase_analytics = getAnalytics(firebase_app);

export const firebase_firebase = getFirestore(firebase_app);
